.custom-select {
  .dropdown-toggle {
    background: none!important;
    color: rgba(0, 0, 0, 0.8);
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    transition: color 0.3s;
    padding: 0;
    &::after {
      display: none;
    }
    svg {
      transform: rotate(180deg);
      margin-left: 14px;
      width: 10px;
      height: 5px;
      transition: stroke 0.3s, transform 0.3s;
    }
    &:active {
      color: var(--red-color) !important;
    }
    &:hover {
      color: var(--red-color);
      svg {
        stroke: var(--red-color);
      }
    }
    &:active {
      color: var(--red-darken-color)!important;
      svg {
        stroke: var(--red-darken-color);
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
  .dropdown-menu {
    padding: 8px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0;
    z-index: 9;
    margin-top: 8px;
  }
  .dropdown-item {
    padding: 8px 10px;
    &:hover {
      background: none;
      color: var(--pink-color);
    }
    &.active {
      background-color: white;
      color: rgba(234, 90, 90, 0.8);
    }
  }
  &.show {
    .dropdown-toggle {
      color: var(--red-color);
      svg {
        transform: rotate(0);
      }
    }
  }
}

@media (max-width: 640px) {
  .custom-select .dropdown-toggle svg {
    margin-left: 10px;
  }
}
