.product-slider {
  display: flex;
  .swiper-button-prev,
  .swiper-button-next {
    color: var(--black-color);
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    transition: background 0.3s;
    left: 0;
    right: 0;
    margin: auto;
    &:hover {
      background: #fdeded;
    }
    &::after {
      font-size: 14px;
    }
  }

  &__thumbs {
    height: 442px;
    overflow: hidden;
    margin-right: 20px;

    .swiper {
      height: 100%;
    }
    .swiper-slide {
      &-thumb-active {
        .product-slider__thumbs-wrapper {
          img {
            border: 1px solid var(--pink-color);
          }
        }
      }
    }

    &-wrapper {
      height: 82px;
      width: 82px;
      border-radius: 5px;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        transform: rotate(90deg);
      }
    }
    .swiper-button-prev {
      bottom: auto;
      top: 0;
    }
    .swiper-button-next {
      top: auto;
      bottom: 0;
    }
  }
  &__main {
    height: 440px;
    width: 440px;
    overflow: hidden;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .swiper-button-prev {
      left: 10px;
      right: auto;
      top: 0;
      bottom: 0;
    }
    .swiper-button-next {
      right: 10px;
      left: auto;
      top: 0;
      bottom: 0;
    }
  }
}

@media (max-width: 991px) {
  .product-slider__main {
    width: 100%;
    flex: 1;
  }
}
@media (max-width: 560px) {
  .product-slider__thumbs-wrapper {
    height: 70px;
    width: 70px;
  }
  .product-slider__main {
    height: 310px;
  }
  .product-slider__thumbs {
    height: 312px;
  }
  .product-slider .swiper-button-prev,
  .product-slider .swiper-button-next {
    width: 30px;
    height: 30px;
  }
}
