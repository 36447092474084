.auth-modal {
  &__body {
    padding: 30px;
    input {
      font-size: 16px;
    }
    label {
      font-size: 16px;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  &__text {
    line-height: 1.2;
  }
  &__btn {
    background: var(--pink-color);
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    padding: 10px 50px;
    display: inline-block;
    margin: 30px auto 0;
    transition: background .3s;
    &:hover {
      background: #e34f4f;
      color: white!important;
    }
    &:active {
      background: #d54a4a;
    }
    &-wrap {
      text-align: center;
    }
    &-close {
      position: absolute;
      top: 25px;
      right: 25px;
      svg {
        width: 24px;
        height: 24px;
        stroke: rgba(0, 0, 0, 0.5);
      }
    }
  }
}