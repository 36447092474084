.custom-counter {
  display: flex;
  justify-content: space-between;
  width: 56px;
  height: 20px;
  background: #f7f7f7;
  margin-right: 10px;
  margin-top: 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  button {
    padding: 0 6px;
  }
  span {
    display: flex;
    align-items: center;
  }
}
