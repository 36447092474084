.order {
  padding-bottom: 140px;
  &-row {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 50px;
  }
  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: var(--black-color) !important;
  }
  &-subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    opacity: 0.5;
    margin: 30px 0;
  }
  &-accordion {
    .accordion-button {
      height: 60px;
      padding: 0 30px;
      background: none!important;
      background: #ffffff;
      box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.03) !important;
      border-radius: 5px;
    }
    .accordion-button::after {
      display: none;
    }
    &-button__arrow {
      width: 30px;
      height: 30px;
      margin-left: auto;
      background: #f6f6fc;
      border-radius: 72px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
    }
    .accordion-button.collapsed .order-accordion-button__arrow {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .accordion-body {
      padding: 15px 30px 0;
      background: #ffffff;
      -webkit-box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.03);
      box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      margin-top: 10px;
      overflow-x: auto;
    }
    .accordion-item {
      border: none;
    }
  }
  &-form {
    &__block {
      background: #ffffff;
      -webkit-box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.03);
      box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      padding: 30px;
      margin-top: 30px;
      .order-title {
        margin-bottom: 30px;
      }
    }
    .form-control {
      border: none;
      background: #f6f6fc;
      border-radius: 5px;
      padding: 15px 30px;
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
      &:focus {
        background: #ffffff;
        box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.1);
      }
    }
    .form-floating {
      label {
        padding: 15px 30px;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
      }
      & > .form-control:not(:-moz-placeholder-shown) ~ label {
        opacity: 0.5;
        transform: scale(0.85) translateY(-0.1rem) translateX(0.3rem);
      }
      & > .form-control:not(:-ms-input-placeholder) ~ label {
        opacity: 0.5;
        transform: scale(0.85) translateY(-0.1rem) translateX(0.3rem);
      }
      & > .form-control-plaintext ~ label,
      & > .form-control:focus ~ label,
      & > .form-control:not(:placeholder-shown) ~ label,
      & > .form-select ~ label {
        opacity: 0.5;
        transform: scale(0.85) translateY(-0.1rem) translateX(0.3rem);
      }
    }
    .row {
      --bs-gutter-x: 15px;
      --bs-gutter-y: 30px;
    }
    .form-check {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      &:first-of-type {
        margin-bottom: 20px;
      }
      &-input {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.5L4.68421 7L11 1' stroke='white'/%3E%3C/svg%3E%0A");
        background-size: 12px 10px;
        &:focus {
          box-shadow: none;
        }
        &:checked {
          background-color: var(--pink-color);
          border-color: var(--pink-color);
        }
      }
      label {
        padding-top: 5px;
        padding-left: 20px;
        cursor: pointer;
      }
    }
    &__shipment-method {
      .order-subtitle {
        margin-bottom: 20px;
      }
      .form-check button {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: var(--blue-color);
        margin-left: 20px;
        margin-top: 5px;
        svg {
          margin-left: 3px;
        }
      }
    }
    &__shipment-comment {
      textarea {
        padding-top: 30px !important;
      }
    }
  }
}
.cart {
  &-table {
    margin-bottom: 0;
    &__head {
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        padding: 15px;
      }
    }
    &__body {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        th {
          vertical-align: middle;
          padding: 30px 15px 30px 0;
        }
      }
      td {
        vertical-align: middle;
        padding: 30px 15px;
        &:last-child {
          padding-right: 0;
        }
      }
    }
    &__img {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    &__title {
      font-weight: 300;
      font-size: 14px;
      line-height: 130%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 240px;
      min-width: 150px;
    }
    &__code {
      font-weight: 300;
      font-size: 14px;
      line-height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      white-space: nowrap;
    }
    &__price {
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      width: 150px;
      overflow-y: hidden;
      overflow-x: auto;
      &--discount {
        color: var(--red-color);
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 0 4px;
        span {
          font-size: 13px;
          text-decoration-line: line-through;
          color: var(--black-color);
          opacity: 0.5;
          margin-bottom: -2px;
        }
      }
    }
    &__padding-left {
      padding-left: 25px !important;
    }
    &__padding-right {
      padding-right: 25px !important;
    }
    .custom-counter {
      margin: 0;
    }
  }
  &-total {
    background: #ffffff;
    box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 30px;
    position: sticky;
    top: 20vh;
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      margin-bottom: 30px;
    }
    &__item {
      &:not(:first-of-type) {
        margin-top: 20px;
      }
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
      }
      p {
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;
      }
    }
    &__divider {
      opacity: 0.2;
      border: 1px solid #000000;
      margin: 30px 0;
    }
    &__sum {
      span {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
      }
      p {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        color: var(--pink-color);
      }
    }
    &__cond {
      text-align: center;
      font-weight: 300;
      font-size: 13px;
      line-height: 130%;
      a {
        color: #659ff6;
        &:hover {
          color: var(--red-color);
        }
      }
    }
  }
  &-btn {
    height: 60px;
    width: 100%;
    background: #ec7070;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #ffffff;
    -webkit-transition: 0.3s background;
    transition: 0.3s background;
    margin: 30px 0;
    &--disabled {
      opacity: 0.9;
      cursor: initial;
      &:active {
        background: #ec7070 !important;
      }
      &:hover {
        background: #ec7070 !important;
      }
    }
    &:hover {
      background: #e86262;
    }
    &:active {
      background: #d95b5b;
    }
  }
}

@media (max-width: 768px) {
  .order {
    padding-bottom: 60px;
    &-form {
      &__block {
        padding: 25px 15px;
        margin-top: 20px;
      }
      .row {
        --bs-gutter-x: 10px;
        --bs-gutter-y: 20px;
      }
      .form-control {
        padding: 10px 15px;
      }
      .form-floating {
        label {
          padding: 10px 15px;
        }
        & > .form-control:not(:-moz-placeholder-shown) ~ label {
          transform: scale(0.85) translateY(-0.1rem) translateX(0);
        }
        & > .form-control:not(:-ms-input-placeholder) ~ label {
          transform: scale(0.85) translateY(-0.1rem) translateX(0);
        }
        & > .form-control-plaintext ~ label,
        & > .form-control:focus ~ label,
        & > .form-control:not(:placeholder-shown) ~ label,
        & > .form-select ~ label {
          -webkit-transform: scale(0.85) translateY(-0.1rem) translateX(0);
          transform: scale(0.85) translateY(-0.1rem) translateX(0);
        }
      }
    }
    &-accordion {
      .accordion-button {
        padding: 0 15px;
      }
      .accordion-body {
        padding: 15px 15px 0;
      }
    }
    &-row {
      --bs-gutter-y: 30px;
    }
  }
  .cart {
    &-total {
      padding: 25px 15px;
    }
    &-table {
      &__price {
        width: 70px;
        font-size: 14px;
        &--discount {
          span {
            font-size: 12px;
          }
        }
      }
      &__img {
        width: 50px;
        height: 50px;
      }
      &__body tr {
        th {
          padding: 15px 10px 15px 0;
        }
        td {
          padding: 15px 10px;
        }
      }
      &__padding-right {
        padding-right: 15px !important;
      }
      &__padding-left {
        padding-left: 15px !important;
      }
    }
  }
}
