.branches {
  background-image: url('../../../assets/images/bg-branches.svg');
  background-size: auto;
  background-position: left top;
  background-repeat: no-repeat;
  padding: 5rem 0 6rem;
  overflow: hidden;

  .section-title {
    padding-left: 60px;
  }

  .container {
    max-width: 1440px;
    padding-right: 0;
  }

  &-wrap {
    display: flex;
    padding-left: 60px;
  }

  &-left {
    width: 197px;
    margin-right: 30px;
  }

  &-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 16px;
    &--red {
      color: var(--pink-color);
    }
  }

  &-schedule {
    padding-bottom: 45px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.3);
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
    }
  }
  &-location {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid rgba(51, 51, 51, 0.3);
    a {
      display: flex;
      align-items: center;
      &:first-of-type {
        margin-bottom: 12px;
      }
      svg {
        margin-right: 4px;
        stroke: var(--black-color);
        fill: var(--black-color);
        opacity: 1;
      }
    }
  }

  &-map {
    flex: 1;
  }
}

@media (max-width: 1400px) {
  .branches {
    .container {
      max-width: 1310px;
      padding-right: 15px;
    }
    .section-title {
      padding-left: 0;
    }
    &-wrap {
      padding-left: 0;
    }
  }
}
@media (max-width: 991px) {
  .branches {
    &-wrap {
      flex-direction: column;
    }
    &-left {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin: 0 0 20px;
    }
    &-schedule {
      padding-bottom: 0;
      padding-right: 5px;
    }
    &-map {
      height: 300px;
      flex: initial;
    }
  }
}
