.custom-pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  a {
    color: var(--black-color);
  }
  .active>.page-link, .page-link.active {
    background: var(--pink-color);
    border-color: var(--pink-color);
    color: white!important;
  }
  .page-link:focus {
    color: var(--black-color);
    box-shadow: 0 0 0 0.25rem rgba(234, 90, 90, 0.25);
  }
}