.terms {
  padding-top: 4.38rem;
  padding-bottom: 6rem;
  &-subtitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
  }
  &-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }
  &-list {
    list-style: initial;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding: 20px;
  }
}