.newsPage {
  padding-bottom: 6rem;
  &-wrap {
    display: flex;
  }
  &-left {
    margin-right: 3.75rem;
    &__img {
      width: 320px;
      height: 320px;
      span {
        width: 100%!important;
        height: 100%!important;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__links {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      a {
        display: flex;
        align-items: center;
        font-weight: 300;
        font-size: 15px;
        line-height: 150%;
      }
      &--prev {
        svg {
          margin-right: 6px;
        }
      }
      &--next {
        svg {
          margin-left: 6px;
          transform: rotate(180deg);
        }
      }
      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  &-content {
    max-width: 700px;
  }
  &-title {
    font-family: var(--second-font), sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 100%;
    max-width: 650px;
  }
  &-date {
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;
    margin: 20px 0;
    opacity: 0.5;
  }
  &-body {
    strong {
      font-weight: bold!important;
      * {
        font-weight: bold!important;
      }
    }
    em {
      font-style: italic!important;
      * {
        font-style: italic!important;
      }
    }
    p, span {
      font-weight: 300;
      font-size: 15px;
      line-height: 150%;
      opacity: 0.8;
      margin: 10px 0;
    }
    h2, h3, h4, h5, h6 {
      font-weight: 400;
      font-size: 18px;
      * {
        font-weight: 400;
        font-size: 18px;
      }
    }
    img {
      max-width: 100%;
      object-fit: contain;
      height: auto;
    }
  }
  &-gallery {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 30px 0;
    img {
      height: 13.75rem;
      width: 100%;
      object-fit: cover;
    }
  }
  &-recommend {
    display: flex;
    flex-direction: column;
    align-items: center;
    .news-grid {
      padding-top: 5rem;
    }
    &__link {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      margin-top: 2.5rem;
      display: flex;
      align-items: center;
      svg {
        margin-left: 14px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .newsPage-gallery img {
    height: 11.75rem;
  }
}

@media (max-width: 991px) {
  .newsPage {
    &-wrap {
      flex-direction: column;
    }
    &-left {
      margin: 0 0 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__img {
        width: 280px;
        height: 280px;
      }
    }
    &-content {
      max-width: 100%;
    }
    &-title {
      max-width: 100%;
      font-size: 32px;
    }
    &-body {
      p, span {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 640px) {
  .newsPage-gallery {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
