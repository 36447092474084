.modal-order {
  &-dialog {
    max-width: 760px;
  }
  &__body {
    padding: 60px;
  }
  &__header {
    margin-bottom: 3.2rem;
  }
  &__title {
    font-family: var(--second-font), sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 24px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
  }
  &__details {
    max-width: 252px;
    margin-bottom: 3.2rem;
    &-item {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
      p {
        opacity: 0.6;
      }
    }
  }
  &__table {
    margin: 0;
    &-container {
      overflow-y: auto;
    }
    &-item {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      td {
        padding: 20px 10px;
        vertical-align: middle;
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }
      &__img {
        width: 36px;
        height: 36px;
        min-width: 36px;
        border-radius: 5px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &__title {
        font-weight: 300;
        font-size: 14px;
        line-height: 130%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 240px;
        width: 100%;
        min-width: 160px;
      }
      &__count,
      .modal-order__table-item__code {
        p {
          font-weight: 500;
          font-size: 13px;
          line-height: 100%;
          opacity: 0.5;
          white-space: nowrap;
        }
        span {
          display: block;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          margin-top: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px;
          white-space: nowrap;
        }
      }
      &__code {
        padding: 0 16px;
      }
      &__price {
        display: flex;
        justify-content: flex-end;
        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 100%;
        }
        &-discount {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          p {
            color: var(--red-color);
          }
          span {
            font-weight: 500;
            font-size: 13px;
            line-height: 100%;
            text-decoration-line: line-through;
            opacity: 0.5;
          }
        }
      }
    }
  }
  &__total {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    &-item {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
      &--ship {
        p {
          font-weight: 500;
          font-size: 13px;
          line-height: 100%;
          opacity: 0.6;
        }
      }
      &--price {
        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 100%;
        }
      }
    }
  }
  &__btn {
    border: 1px solid var(--red-color);
    width: 210px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: var(--pink-color);
    margin-top: 3.75rem;
    &-close {
      position: absolute;
      top: 30px;
      right: 40px;
      svg {
        width: 25px;
        height: 25px;
        stroke: #333333;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-order {
    &__body {
      padding: 20px;
    }
    &__btn-close {
      top: 20px;
      right: 20px;
    }
  }
}
