.profile {
  padding-bottom: 6rem;
  overflow: hidden;
  &-info {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 60px;
    margin-bottom: 5rem;
    &__img {
      width: 260px;
      height: 260px;
      border-radius: 5px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
    &__item {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
      &-title {
        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
        opacity: 0.5;
      }
      &-text {
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        margin-top: 14px;
        &--small {
          font-size: 14px;
          margin-top: 5px;
          span {
            color: var(--red-color);
          }
          button {
            color: var(--red-color);
          }
        }
      }
    }
  }
  &-history {
    &__title {
      font-family: var(--second-font), sans-serif;
      font-weight: 400;
      font-size: 30px;
      line-height: 100%;
      margin-bottom: 30px;
    }
    &__text {
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
      opacity: 0.6;
    }
    &__table {
      &-wrap {
        overflow-x: auto;
        max-height: 500px;
      }
      &-head {
        background: #f2f2f2;
        tr th {
          padding: 20px 30px;
          font-weight: 400;
          font-size: 15px;
          line-height: 100%;
        }
      }
      &-item {
        &:first-of-type {
          td {
            padding-top: 22px;
          }
        }
        td {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: rgba(51, 51, 51, 0.6);
          padding: 14px 30px 0 30px;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
            white-space: nowrap;
          }
        }
        &--details {
          display: flex;
          justify-content: flex-end;
          padding-right: 14px !important;
        }
        &__btn {
          background: #f0f0f0;
          border-radius: 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          padding: 8px 16px;
        }
        &__cell--wrap {
          white-space: break-spaces!important;
          word-break: break-all;
        }
      }
    }
  }
  &-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    svg {
      margin-left: 5px;
    }
    &--photo {
      justify-content: flex-start;
      svg {
        fill: rgba(51, 51, 51, 0.5);
      }
      cursor: pointer;
      input {
        visibility: hidden;
        font-size: 0;
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
    }
    &-exit {
      background: var(--pink-color);
      padding: 10px 30px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
      color: #FFFFFF;
      display: flex;
      margin-left: auto;
      transition: background .3s;
      margin-bottom: 20px;
      &:hover {
        background: #e34f4f;
      }
      &:active {
        background: #d54a4a;
      }
    }
    &-admin {
      margin-bottom: 10px;
      &:hover {
        color: white!important;
      }
    }
  }
  &-edit {
    &__top {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 30px;
      margin-bottom: 30px;
      &-img {
        width: 130px;
        height: 130px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
      &-content {
        padding-top: 10px;
      }
      &-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
      }
      .profile-btn--photo {
        margin-top: 78px;
      }
    }
    &__form {
      max-width: 795px;
      .row {
        --bs-gutter-y: 30px;
        --bs-gutter-x: 45px;
      }
      &-label {
        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
        opacity: 0.5;
        margin-bottom: 7px;
      }
      &-input {
        background: #f6f6fc;
        border-radius: 5px;
        height: 60px;
        padding: 0 30px;
        border: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        &:focus {
          background: #ffffff;
          box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.1);
        }
      }
      &-bottom {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 60px;
      }
    }
    &__btn {
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
      padding: 23px 40px 22px;
      border-radius: 5px;

      &--save {
        background: var(--pink-color);
        color: white;
        transition: background 0.3s;
        &:hover {
          background: #e34f4f;
        }
        &:active {
          background: #d54a4a;
        }
      }
      &--cancel {
        background: #f8f8f8;
      }
    }
  }
}